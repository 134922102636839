<template>
  <v-card-text class="mt-12 border-s-lg">
    <h3 class="text-center text-sm-h5">Si ya eres usuario de CrediGO</h3>
    <div class="text-center text-caption mt-10">
      <div class="text-sm-h6">Ingrese con su usuario y clave </div>
      <div>Podrá revisar los créditos solicitados y las ofertas que ha tenido de las distintas entidades financieras. </div>
    </div>
    <v-form ref="form" v-model="form.validForm" @submit.prevent="submit">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" class="mt-16">
          <v-text-field
            label="Email"
            variant="outlined"
            autofocus
            ref="formEmail"
            v-model="form.mail"
            density="compact"
            autocomplete="false"
            prepend-inner-icon="mdi-email-outline"
            :loading="form.validating"
            :disabled="form.validating"
            :rules="[rulesMail.requerido, rulesMail.minChar, rulesMail.valida]"
          ></v-text-field>
          <v-text-field
            label="Password"
            variant="outlined"
            density="compact"
            autocomplete="false"
            type="password"
            v-model="form.password"
            :loading="form.validating"
            :disabled="form.validating"
            prepend-inner-icon="mdi-lock"
            :rules="[
              rulesClave.requerido,
              rulesClave.minChar,
              rulesClave.maxChar,
            ]"
          ></v-text-field>
          <v-row>
            <v-col cols="12" sm="7">
              <v-checkbox label="Remember me" class="mt-n1"></v-checkbox>
            </v-col>
            <v-col cols="12" sm="5">
              <span class="caption text-blue" @click="cambiarPaso(6)"
                >Recuperar Clave</span
              >
            </v-col>
          </v-row>
          <v-btn
            color="orange-lighten-2"
            dark
            block
            title
            type="submit"
            :loading="form.validating"
            >Ingresar</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>

<script>
import { enviarJsonPOST } from "@/helpers/funcionesEstandard";

import { rulesMail, rulesClave } from "@/helpers/funcionesEstandard";

export default {
  data: () => ({
    form: {
      validForm: null,
      validating: null,
      mail: null,
      password: null,
    },
    rulesMail: rulesMail,
    rulesClave: rulesClave,
  }),
  watch: {
    step: function (val) {
      this.form = {
        validForm: null,
        validating: null,
        mail: null,
        password: null,
      };
      if (val == 1) {
        this.$refs.form.reset();
        this.$refs.formEmail.focus();
      }
    },
  },
  props: {
    step: Number,
    user: Object,
  },
  mounted: function(){
    this.$emit('setUsuario',null);
  },
  methods: {
    submit: async function () {
      this.form.validating = true;
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        let fd = new FormData();
        fd.append("email", this.form.mail);
        fd.append("clave", this.form.password);
        var json = await enviarJsonPOST("usuarios/validar_ingreso_usuario", fd);

        if (json.error != 0) {
          await this.$emit("validarMensaje", json);
        } else {
          if (
            json.data.estado_usuario == 200 ||
            json.data.estado_usuario == 300
          ) {
            this.$emit("registraCorreo",this.form.mail);
            this.$emit("cambiarPaso", 5);
          } else {
            this.$emit("cambiarPaso", 10);
            this.$emit('setUsuario',json.data);
          }
        }
      }
      this.form.validating = false;
    },
    cambiarPaso: async function (paso) {
      this.$emit("cambiarPaso", paso);
    },
  },
};
</script>
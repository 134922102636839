<template>
  <v-row>
    <v-col cols="12" sm="8" lg="9">
      <v-form
        ref="formNew"
        v-model="formNew.validForm"
        @submit.prevent="submitNew"
      >
        <v-row class="mt-10 ml-5 mr-5 mb-5">
          <v-col cols="12">
            Vamos por una nueva solicitud de Crédito de consumo
            {{ user.name }}
          </v-col>
          <v-col cols="12">
            <baseNuevoCredito
              :user="user"
              @setValConsumo2="setValConsumo"
            ></baseNuevoCredito>
          </v-col>

          <v-col cols="6" align="center">
            <v-btn
              color="blue"
              dark
              block
              title
              class="mt-8"
              type="submit"
              :loading="formNew.validating"
              >Guardar</v-btn
            >
            </v-col>
          <v-col cols="6" align="center">
            <v-btn
              dark
              block
              title
              class="mt-8"
              :loading="formNew.validating"
              @click="volver"
              >Volver</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-col
      cols="12"
      sm="4"
      lg="3"
      class="bg-orange-lighten-3 rounded-bs-circle"
      align="center"
    >
      <br />
      <opcionesMenuRapido :user="user"></opcionesMenuRapido>
    </v-col>
  </v-row>
</template>
  
<script>
import { enviarJsonPOST } from "@/helpers/funcionesEstandard";
import opcionesMenuRapido from "@/components/principal/opcionesMenuRapido.vue";
import baseNuevoCredito from "@/components/creditoConsumo/baseNuevoCredito.vue";

export default {
  data: () => ({
    formNew: {
      personaId: null,
      usuarioId: null,
      validForm: null,
      validating: null,
      nombreCredito: null,
      descripcionCredito: null,
      deudaTotal: null,
      deudaMensual: null,
      sueldoLiquido: null,
      montoSolicitar: null,
      numeroCuotas: null,
      fechaPrimeraCuota: null,
      sd: null,
      ci: null,
      it: null,
      codigo_tipo_credito: null,
    },
  }),
  components: {
    opcionesMenuRapido,
    baseNuevoCredito,
  },
  props: {
    user: Object,
  },
  mounted: async function () {
        
  },
  methods: {
    volver: function(){
      this.$router.push('/menuPrincipal');
    },
    submitNew: async function () {
      const { valid } = await this.$refs.formNew.validate();
      if (valid) {
        let fd = new FormData();
        fd.append("nombreCredito", this.formNew.nombreCredito);
        fd.append("descripcionCredito", this.formNew.descripcionCredito);
        fd.append("deudaTotal", this.formNew.deudaTotal);
        fd.append("deudaMensual", this.formNew.deudaMensual);
        fd.append("sueldoLiquido", this.formNew.sueldoLiquido);
        fd.append("montoSolicitar", this.formNew.montoSolicitar);
        fd.append("numeroCuotas", this.formNew.numeroCuotas);
        fd.append("fechaPrimeraCuota", this.formNew.fechaPrimeraCuota);
        fd.append("sd", this.formNew.sd);
        fd.append("ci", this.formNew.ci);
        fd.append("it", this.formNew.it);
        var json = await enviarJsonPOST(
          "creditoConsumo/guardar_nuevo_consumo",
          fd
        );

        if(json.error == 0){
          this.$router.push('/menuPrincipal');
        }else{
          this.validarMensaje(json);
        }
      }
    },
    setValConsumo: function (clave, valor) {
      this.formNew[clave] = valor;
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
  },
};
</script>
<template>
  <v-window v-model="step">
    <v-window-item :value="1">
      <v-row>
        <v-col
          cols="12"
          sm="7"
          class="bg-orange-lighten-3 rounded-be-circle text-blue"
        >
        <v-img src="/img/sitio_en_construccion.png"></v-img>
          <!---IngresoInicialSistema
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
          ></IngresoInicialSistema-->
        </v-col>
        <v-col cols="12" sm="5">
            
        <v-img src="/img/sitio_en_construccion.png"></v-img>
          <!--ingresoUsuarioRegistrado
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
            @registraCorreo="registraCorreo"
            @setUsuario="setUsuario"
            :step="step"
            :email="email"
          ></ingresoUsuarioRegistrado-->
        </v-col>
        <v-col cols="12" class="pa-6">
          <v-card class="bg-orange-lighten-5">
            <v-card-title> ¡Bienvenido a CrediGO! </v-card-title>
            <v-card-text class="elevation-6">
              <p class="mb-4">
                En CrediGO, entendemos que cada persona y empresa tiene
                necesidades financieras únicas. Es por eso que nos dedicamos a
                ser tu aliado confiable en el mundo de los créditos,
                ofreciéndote acceso a las mejores ofertas adaptadas a tus
                requerimientos específicos.
              </p>
              <p class="mb-4">
                ¿Buscas un préstamo personal para alcanzar tus metas? ¿Necesitas
                financiamiento para hacer crecer tu negocio? No importa cuál sea
                tu situación, estamos aquí para ayudarte a encontrar la solución
                perfecta.
              </p>
              <p class="mb-4">
                Nuestro equipo de expertos en financiamiento está comprometido a
                brindarte un servicio personalizado y transparente en cada paso
                del proceso. Desde la evaluación inicial hasta la obtención del
                crédito adecuado, estaremos contigo en todo momento,
                proporcionándote orientación y apoyo.
              </p>
              <p class="mb-4">
                En CrediGO, creemos en la importancia de la transparencia y la
                honestidad en todas nuestras interacciones. Nos esforzamos por
                ofrecerte una experiencia sin complicaciones y sin sorpresas
                desagradables.
              </p>
              <p class="mb-4">
                Explora nuestro sitio para descubrir una amplia gama de opciones
                de crédito, aprende más sobre nuestros servicios y conoce las
                historias de éxito de nuestros clientes. Estamos aquí para
                ayudarte a alcanzar tus metas financieras de manera rápida y
                eficiente.
              </p>
              <p class="mb-4">
                ¡Gracias por elegir CrediGO como tu socio financiero de
                confianza!
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-window-item>

    <v-window-item :value="2">
      <v-row>
        <v-col cols="12" sm="8">
          <SolicitudCreditoUsuarioNoRegistrado
            @cambiarPaso="cambiarPaso"
            @registraCorreo="registraCorreo"
            @validarMensaje="validarMensaje"
            :step="step"
          ></SolicitudCreditoUsuarioNoRegistrado>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="bg-orange-lighten-3 rounded-be-pill text-blue"
        >
          <seccionIngresoUsuario
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
          ></seccionIngresoUsuario>
        </v-col>
      </v-row>
    </v-window-item>

    <v-window-item :value="3">
      <v-row>
        <v-col cols="12" sm="6">
          <validaCorreoNuevo
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
            :step="step"
            :email="email"
            :codigo="codigo"
          ></validaCorreoNuevo>
        </v-col>
        <v-col cols="12" sm="6" class="bg-orange-lighten-3 rounded-bs-circle">
          <seccionIngresoUsuario
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
          ></seccionIngresoUsuario>
        </v-col>
      </v-row>
    </v-window-item>

    <v-window-item :value="4">
      <div class="pa-4 text-center">
        <v-img class="mb-4" height="128" src="/img/credigo.png" contain></v-img>
        <div class="text-h6 text-sm-h3 font-weight-light mb-2">
          CrediGO agradece su preferencia!
        </div>
        <div class="text-caption text-sm-h4 text-grey bm-5">
          Ha sido enviado un correo electronico a {{ email }} con instrucciones
          para continuar con el proceso.
        </div>
        <v-btn @click="cambiarPaso(1)" variant="outlined" class="bg-blue"
          >Ingresar al sistema</v-btn
        >
      </div>
    </v-window-item>

    <v-window-item :value="5">
      <v-row>
        <v-col cols="12" sm="6" class="bg-orange-lighten-3 rounded-be-circle">
          <seccionIngresoUsuario
            @cambiarPaso="cambiarPaso"
          ></seccionIngresoUsuario>
        </v-col>
        <v-col cols="12" sm="6">
          <cambioClaveTemporal
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
            :step="step"
            :email="email"
            :passwordOld="passwordOld"
          ></cambioClaveTemporal>
        </v-col>
      </v-row>
    </v-window-item>
    <v-window-item :value="6">
      <v-row>
        <v-col cols="12" sm="6" class="bg-orange-lighten-3 rounded-be-circle">
          <seccionIngresoUsuario
            @cambiarPaso="cambiarPaso"
          ></seccionIngresoUsuario>
        </v-col>
        <v-col cols="12" sm="6">
          <recuperarClave
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
            :step="step"
          ></recuperarClave>
        </v-col>
      </v-row>
    </v-window-item>
  </v-window>
</template>

<script>
var clave = null;
import {
  rulesRut,
  rulesMail,
  rulesName,
  rulesApellido,
  rulesEmpresa,
  rulesClave,
} from "@/helpers/funcionesEstandard";

import seccionIngresoUsuario from "@/components/seguridad/seccionIngresoUsuario.vue";
import IngresoInicialSistema from "@/components/seguridad/IngresoInicialSistema.vue";
import ingresoUsuarioRegistrado from "@/components/seguridad/ingresoUsuarioRegistrado.vue";
import SolicitudCreditoUsuarioNoRegistrado from "@/components/seguridad/SolicitudCreditoUsuarioNoRegistrado.vue";
import validaCorreoNuevo from "@/components/seguridad/validaCorreoNuevo.vue";
import cambioClaveTemporal from "@/components/seguridad/cambioClaveTemporal.vue";
import recuperarClave from "@/components/seguridad/recuperarClave.vue";

export default {
  data: () => ({
    email: null,
    codigo: null,
    passwordOld: null,
    msg: {
      number: null,
      code: null,
      title: null,
      text: null,
    },
    dialogEvalMessage: false,
    step: 1,
    rulesRut: rulesRut,
    rulesMail: rulesMail,
    rulesName: rulesName,
    rulesApellido: rulesApellido,
    rulesEmpresa: rulesEmpresa,
    rulesClave: rulesClave,
  }),
  props: {
    user: Object,
  },
  components: {
    seccionIngresoUsuario,
    IngresoInicialSistema,
    ingresoUsuarioRegistrado,
    SolicitudCreditoUsuarioNoRegistrado,
    validaCorreoNuevo,
    cambioClaveTemporal,
    recuperarClave,
  },
  watch: {
    tipoRegistroNuevo: function (val) {
      requiereEmpresa = val == "1" ? true : false;
    },
  },
  mounted: function () {
    if (this.$route.params.codigo) {
      this.email = this.$route.params.email;
      this.codigo = this.$route.params.codigo;
      this.cambiarPaso(3);
    } else if (this.$route.params.clave) {
      this.cambiarPaso(5);
      this.email = this.$route.params.email;
      this.passwordOld = this.$route.params.clave;
    }
  },
  methods: {
    cambiarPaso: async function (paso) {
      this.step = paso;
      switch (this.step) {
        case 10:
          this.$router.push("menuPrincipal");
          break;
      }
    },
    registraCorreo: async function (email) {
      this.email = email;
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    setUsuario: async function (usuario) {
      this.$emit("setUsuario", usuario);
    },
  },
};
</script>

<style>
.v-appication .rounded-bs-xl {
  border-bottom-left-radius: 300px !important;
}

.v-appication .rounded-bl-xl {
  border-bottom-right-radius: 300px !important;
}
</style>
<template>
  <div
    style="text-align: center"
    class="pt-5 pt-sm-10 pb-10 pb-sm-10 pl-5 pl-sm-10"
  >
    <v-row class="mr-16 mb-16" align="center" justify="center">
      <v-col cols="12" sm="12">
        <v-card-text class="text-blue-darken-4">
          <div align="center" justify="center" class="mt-1 mb-10">
            <v-img
              src="/img/credigo.png"
              width="30%"
              class="bg-white rounded-xl"
            ></v-img>
          </div>
          <h3 class="text-center text-h5 text-sm-h3">¿En que te podemos ayudar?</h3>
          <span class="text-center text-h6 text-sm-h4">Seleccione la opción que buscas</span>
        </v-card-text>
      </v-col>
      <v-col cols="12" align="center">
        <v-icon size="x-large">
          mdi-arrow-down-bold-outline
        </v-icon>
      </v-col>
      <v-col cols="6" sm="6" lg="4" align="center" justify="center">
        <v-img
          class="cursor-pointer"
          :width="150"
          aspect-ratio="16/9"
          cover
          src="/img/credito_consumo.png"
          @click="cambiarPaso(2)"
        ></v-img>
      </v-col>
      <v-col cols="6" sm="6" lg="4" align="center" justify="center">
        <v-img
          :width="150"
          aspect-ratio="16/9"
          cover
          src="/img/credito_hipotecario.png"
        ></v-img>
      </v-col>
      <v-col cols="6" sm="6" lg="4" align="center" justify="center">
        <v-img
          :width="150"
          aspect-ratio="16/9"
          cover
          src="/img/credito_automotriz.png"
        ></v-img>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import validaCorreoNuevo from "./validaCorreoNuevo.vue";
export default {
  components: { validaCorreoNuevo },
  methods: {
    cambiarPaso: async function (paso) {
      this.$emit("cambiarPaso", paso);
    },
  },
};
</script>
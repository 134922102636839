<template>
  <v-card-text class="mt-12 border-s-lg">
    <v-form
      ref="formOPT"
      v-model="formOPT.validForm"
      @submit.prevent="submitOPT"
    >
      <h3 class="text-center">Validación de correo electrónico</h3>
      <div class="text-center text-caption">
        Fue enviado un correo electrónico a
        <span class="text-blue">{{ email }}</span>
        <br />por favor revise su correo electrónico registre el código enviado
        <br />con esta validación, usted podrá continuar con su registro de
        usuario...
      </div>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" class="mt-8">
          <v-row>
            <v-col cols="12">
              <v-otp-input
                v-model="formOPT.opt"
                ref="formOPTopt"
                autofocus
                color="primary"
                focus-all
                :loading="formOPT.validating"
                :disabled="formOPT.validating"
                variant="outlined"
                :rules="[rulesOPT.requerido, rulesOPT.minChar]"
              ></v-otp-input>
            </v-col>
          </v-row>
          <v-btn
            color="blue"
            dark
            block
            title
            class="mt-8"
            type="submit"
            :loading="formOPT.validating"
            :disabled="formOPT.opt.length < 6 || formOPT.validating"
            >Validar</v-btn
          >
          <!--h5 class="text-center text-grey mt-4 mb-3"> Or log in using</h5>
                                                <div class="d-flex justify-space-between align-center mx-10 mb-16">
                                                    <v-btn depressed outlined color="gray">
                                                        <v-icon color="red">mdi-google</v-icon>
                                                    </v-btn>
                                                    <v-btn depressed outlined color="gray">
                                                        <v-icon color="blue">mdi-facebook</v-icon>
                                                    </v-btn>
                                                    <v-btn depressed outlined color="gray">
                                                        <v-icon color="lighur">mdi-instagram</v-icon>
                                                    </v-btn>
                                                </div-->
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>

<script>
import { enviarJsonPOST } from "@/helpers/funcionesEstandard";

export default {
  data: () => ({
    formOPT: {
      validForm: null,
      loading: null,
      validating: null,
      opt: "",
      email: "",
    },
    rulesOPT: {
      requerido: (value) => !!value || "El código es requerido",
      minChar: (value) =>
        (value && value.length == 6) ||
        "Debe tener al menos 10 dígitos (aaa@aaa.cl)",
    },
  }),
  mounted: function () {
    this.revisarPaso("mounted");
  },
  watch: {
    step: function (val) {
      this.revisarPaso("watch");
    },
  },
  props: {
    step: Number,
    email: String,
    codigo: String,
  },
  methods: {
    revisarPaso: async function (evento) {
      if (this.step == 3) {
        this.formOPT = {
          validForm: null,
          loading: null,
          validating: null,
          opt: "",
        };
        if (this.$refs.formOPT) {
          this.$refs.formOPT.reset();
        }
        if (this.$refs.formOPTopt) {
          this.$refs.formOPTopt.focus();
        }
        if (this.codigo) {
          this.formOPT.opt = this.codigo;
        }
        if (this.email) {
          this.formOPT.email = this.email;
        }
      }
    },
    submitOPT: async function () {
      this.formOPT.validating = true;

      const { valid } = await this.$refs.formOPT.validate();
      if (valid) {
        let fd = new FormData();
        fd.append("email", this.formOPT.email);
        fd.append("codigo", this.formOPT.opt);
        var json = await enviarJsonPOST(
          "usuarios/validar_codigo_correo_nuevo",
          fd
        );
        if (json.error == 0) {
          await this.$emit("cambiarPaso", 4);
        } else {
          await this.$emit("validarMensaje", json);
        }
      }
      this.formOPT.validating = false;
    },
  },
};
</script>
<template>
  <v-app>
    <v-main>
      <v-row align="center" justify="center">
        <v-col cols="11" sm="10" lg="8">
          <v-card class="elevation-6 mt-10 mb-5">
            <router-view :user="user" :usuarioId="usuarioId" @validarMensaje="validarMensaje" @setUsuario="setUsuario"/>
          </v-card>
        </v-col>
      </v-row>
      <v-footer align="right" class="text-caption">Entorno ({{ entorno }}) - Versión ({{ version }})</v-footer>

    </v-main>
    <v-dialog persistent width="auto"  max-width="70%" v-if="dialogEvalMessage" v-model="dialogEvalMessage">
      <v-card class="elevation-6 mt-10">
        <v-toolbar :title="msg.title">{{ msg.code }} {{ (msg.number) ? ' : ' + msg.number : '' }}</v-toolbar>
        <v-divider class="mt-3"></v-divider>
        <v-card-text>
          {{ msg.text }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col cols="12" align="center">
              <v-btn variant="outlined" class="bg-blue" @click="cerrar">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { createLogger } from 'vuex';
export default {
  name: 'App',

  data: () => ({
    user: {
      token: null,
      name: null,
      usuarioId: null 
    },
    entorno: process.env.NODE_ENV,
    version: process.env.VUE_APP_VERSION,
    msg: {
      number: null,
      code: null,
      title: null,
      text: null
    },
    dialogEvalMessage: false,
  }),
  mounted: function(){
    if(sessionStorage.getItem('user')){ 
        this.user = JSON.parse(sessionStorage.getItem('user'));
    }
  },
  methods: {
    setUsuario: async function(usuario){
      
      if(typeof  usuario == "object" && usuario != null){
        this.user.token = usuario.token;
        this.user.name = usuario.nombre;
        this.user.usuarioId = usuario.id;
        const parsed = JSON.stringify(this.user);
        sessionStorage.setItem('user', parsed);
      }else{
        sessionStorage.setItem('user', null);
        sessionStorage.removeItem('user');
      }

    },
    validarMensaje: async function (msgAjax) {
      if (typeof msgAjax == "object") {
        this.msg.number = msgAjax.error;
        this.msg.code = msgAjax.code;
        this.msg.title = msgAjax.title;
        this.msg.text = msgAjax.message;
      } else {
        this.msg.number = '0000';
        this.msg.code = '0000';
        this.msg.title = 'ERROR INESPERADO';
        this.msg.text = "El mensaje de error no pudo ser interpretado correctamente, el sistema se cerrará. Por favor volver a ingresar.";
      }
      this.dialogEvalMessage = true;
    },
    cerrar: async function () {
      this.dialogEvalMessage = false;
      if (this.msg.number == 1000) {
        this.$router.push({ path: "/login" });
      }
    }
  }
}
</script>

<style>
.captcha,
.v-label,
.v-field-label,
.label,
.v-label--clickable,
.v-selection-control {
  font-size: 12px !important;
}

.v-label {
  font-size: 12px;
}
.v-input__details {
  font-size: 12px;
}
.v-field__input {
  font-size: 12px;
}
.cardColor {
  background-color: white !important;
  border-color: transparent !important;
  opacity: 0.8;
}

.border-col{
  padding: 3px;
}
</style>
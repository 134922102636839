<template>
  <v-row>
    <v-col cols="12" sm="4" class="border-col">
      <v-text-field autofocus
        label="Monto a solciitar"
        v-model="montoSolicitar"
        variant="outlined"
        density="compact"
        autocomplete="false"
        type="number"
        :loading="validating"
        :disabled="validating"
        prepend-inner-icon="mdi-currency-usd"
        :rules="[
          rulesMontoCredConsumo.requerido,
          rulesMontoCredConsumo.minChar,
          rulesMontoCredConsumo.maxChar,
          rulesMontoCredConsumo.minVal,
          rulesMontoCredConsumo.maxVal,
        ]"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="4" class="border-col">
      <v-text-field
        label="Numero de cuotas"
        v-model="numeroCuotas"
        variant="outlined"
        density="compact"
        autocomplete="false"
        type="number"
        :loading="validating"
        :disabled="validating"
        prepend-inner-icon="mdi-calendar-clock"
        :rules="[
          rulesCuotasConsumo.requerido,
          rulesCuotasConsumo.minChar,
          rulesCuotasConsumo.maxChar,
          rulesCuotasConsumo.minVal,
          rulesCuotasConsumo.maxVal,
        ]"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="4" class="border-col">
      <v-text-field
        label="Fecha primera cuota"
        v-model="fechaPrimeraCuota"
        variant="outlined"
        density="compact"
        autocomplete="false"
        type="date"
        :loading="validating"
        :disabled="validating"
        :min="MinFec"
        :max="MaxFec"
        :rules="[rulesCuotasConsumo.requerido]"
        prepend-inner-icon="mdi-calendar-check"
      ></v-text-field>
    </v-col>
    <v-col cols="12" class="border-col ml-4">
      <v-row>
        <v-col cols="12" sm="4" class="border-col">
          <v-switch
            label="Degravamen"
            color="primary"
            :loading="validating"
            :disabled="validating"
            v-model="sd"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="4" class="border-col">
          <v-switch
            label="Cesantía involuntaria"
            color="primary"
            :loading="validating"
            :disabled="validating"
            v-model="ci"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="4" class="border-col">
          <v-switch
            label="Incapacidad temporal"
            color="primary"
            :loading="validating"
            :disabled="validating"
            v-model="it"
          ></v-switch>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="border-col">
      <v-text-field
        label="Nombre del crédito"
        v-model="nombreCredito"
        variant="outlined"
        density="compact"
        autocomplete="false"
        :loading="validating"
        :disabled="validating"
        prepend-inner-icon="mdi-bank-outline"
        :rules="[
          rulesNameCredito.requerido,
          rulesNameCredito.minChar,
          rulesNameCredito.maxChar,
        ]"
      ></v-text-field>
    </v-col>
    <v-col cols="12" class="border-col">
      <v-textarea
        label="Destino del crédito"
        v-model="descripcionCredito"
        variant="outlined"
        density="compact"
        autocomplete="false"
        :loading="validating"
        :disabled="validating"
        prepend-inner-icon="mdi-image-text"
        rows="2"
      ></v-textarea>
    </v-col>

    <v-col cols="12" sm="4" class="border-col">
      <v-text-field
        label="Sueldo líquido"
        ref="sueldoLiquido"
        v-model="sueldoLiquido"
        variant="outlined"
        density="compact"
        autocomplete="false"
        type="number"
        :loading="validating"
        :disabled="validating"
        prepend-inner-icon="mdi-currency-usd"
        :rules="[
          rulesSueldo.requerido,
          rulesSueldo.minChar,
          rulesSueldo.maxChar,
          rulesSueldo.minVal,
          rulesSueldo.maxVal,
        ]"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="4" class="border-col">
      <v-text-field
        label="Monto total cuotas mensuales"
        v-model="deudaMensual"
        variant="outlined"
        density="compact"
        autocomplete="false"
        type="number"
        :loading="validating"
        :disabled="validating"
        prepend-inner-icon="mdi-currency-usd"
        :rules="[
          rulesMonedaMayIgCero.requerido,
          rulesMonedaMayIgCero.minChar,
          rulesMonedaMayIgCero.maxChar,
          rulesMonedaMayIgCero.minVal,
          rulesMonedaMayIgCero.maxVal,
        ]"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="4" class="border-col">
      <v-text-field
        label="Deuda Total"
        v-model="deudaTotal"
        variant="outlined"
        density="compact"
        autocomplete="false"
        type="number"
        :loading="validating"
        :disabled="validating"
        prepend-inner-icon="mdi-currency-usd"
        :rules="[
          rulesMonedaMayIgCero.requerido,
          rulesMonedaMayIgCero.minChar,
          rulesMonedaMayIgCero.maxChar,
          rulesMonedaMayIgCero.minVal,
          rulesMonedaMayIgCero.maxVal,
        ]"
      ></v-text-field>
    </v-col>

    
  </v-row>
</template>

<script>
import { enviarJsonPOST,formatoFechaVue,agregarMeses } from "@/helpers/funcionesEstandard";
import {
  rulesRut,
  rulesMail,
  rulesName,
  rulesApellido,
  rulesEmpresa,
  rulesClave,
  rulesNameCredito,
  rulesMonedaMayIgCero,
  rulesSueldo,
  rulesMontoCredConsumo,
  rulesCuotasConsumo,
} from "@/helpers/funcionesEstandard";

export default {
  data: () => ({
    nombreCredito: null,
    descripcionCredito: null,
    sueldoLiquido: null,
    deudaMensual: null,
    deudaTotal: null,
    montoSolicitar: null,
    numeroCuotas: null,
    fechaPrimeraCuota: null,
    sd: null,
    ci: null,
    it: null,

    MaxFec: null,
    MinFec: null,

    rulesMail: rulesMail,
    rulesRut: rulesRut,
    rulesName: rulesName,
    rulesApellido: rulesApellido,
    rulesEmpresa: rulesEmpresa,
    rulesClave: rulesClave,
    rulesNameCredito: rulesNameCredito,
    rulesMonedaMayIgCero: rulesMonedaMayIgCero,
    rulesSueldo: rulesSueldo,
    rulesMontoCredConsumo: rulesMontoCredConsumo,
    rulesCuotasConsumo: rulesCuotasConsumo,
  }),
  props: {
    validating: Boolean,
  },
  watch: {
    nombreCredito: function (val) {
      this.$emit("setValConsumo2", "nombreCredito", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    descripcionCredito: function (val) {
      this.$emit("setValConsumo2", "descripcionCredito", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    sueldoLiquido: function (val) {
      this.$emit("setValConsumo2", "sueldoLiquido", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    deudaMensual: function (val) {
      this.$emit("setValConsumo2", "deudaMensual", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    deudaTotal: function (val) {
      this.$emit("setValConsumo2", "deudaTotal", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    montoSolicitar: function (val) {
      this.$emit("setValConsumo2", "montoSolicitar", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    numeroCuotas: function (val) {
      this.$emit("setValConsumo2", "numeroCuotas", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    fechaPrimeraCuota: function (val) {
      this.$emit("setValConsumo2", "fechaPrimeraCuota", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    sd: function (val) {
      this.$emit("setValConsumo2", "sd", val);
    },
    ci: function (val) {
      this.$emit("setValConsumo2", "ci", val);
    },
    it: function (val) {
      this.$emit("setValConsumo2", "it", val);
    },
  },
  mounted: async function () {
    var fec = new Date();
    let fec2 = await agregarMeses(fec,6);
    this.MinFec = await formatoFechaVue(fec);
    //this.MaxFec = fec2;

    let fd = new FormData();
    var json = await enviarJsonPOST(
      "creditoConsumo/datos_historico_antecedentes",
      fd
    );
    if (json.error == 0) {
      if (json.error.data) {
        this.sueldoLiquido = json.data.sueldo_liquido;
        this.deudaMensual = json.data.deuda_mensual;
        this.deudaTotal = json.data.deuda_total;
      }
    } else {
      this.validarMensaje(json);
    }
  },
};
</script>
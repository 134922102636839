<template>
  <v-card-text class="mt-12 ml-2 mr-2 border-s-lg">
    <v-form
      ref="formNew"
      v-model="formNew.validForm"
      @submit.prevent="submitNew"
    >
      <h3 class="text-center">Solicitud de Crédito de consumo</h3>
      <div class="text-center text-caption">
        Registra que necesitas y los ejecutivos competirán por ofrecerte las mejores opciones.
        <br />Tú eliges las condiciones que más te convengan. ¡Tú decides!
      </div>
      <v-row align="center" justify="center">
        <v-col cols="12" class="mt-8">
          <v-row>
            
            <v-col cols="12">
              <nuevoCredito
                @setValConsumo="setValConsumo"
                @setValConsumo2="setValConsumo2"
                :validating="formNew.validating"
              ></nuevoCredito>
            </v-col>
            <v-col cols="12" sm="8" class="border-col">
              <v-text-field
                label="Email"
                variant="outlined"
                ref="formNewEmail"
                v-model="formNew.email"
                density="compact"
                autocomplete="false"
                prepend-inner-icon="mdi-email-outline"
                :loading="formNew.validating"
                :disabled="formNew.validating"
                :rules="[
                  rulesMail.requerido,
                  rulesMail.minChar,
                  rulesMail.valida,
                ]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="border-col"
              v-if="tipoRegistroNuevo == '1'"
            >
              <v-text-field
                label="RUT"
                v-model="formNew.rutUsuario"
                variant="outlined"
                density="compact"
                autocomplete="false"
                :loading="formNew.validating"
                :disabled="formNew.validating"
                :rules="[rulesRut.requerido, rulesRut.minChar, rulesRut.valida]"
                prepend-inner-icon="mdi-card-account-details-outline"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="border-col">
              <v-text-field
                label="Nombres"
                variant="outlined"
                v-model="formNew.nombres"
                density="compact"
                autocomplete="false"
                :loading="formNew.validating"
                :disabled="formNew.validating"
                prepend-inner-icon="mdi-account-outline"
                :rules="[
                  rulesName.requerido,
                  rulesName.minChar,
                  rulesName.maxChar,
                ]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" class="border-col">
              <v-text-field
                label="Apellido Paterno"
                variant="outlined"
                v-model="formNew.app"
                density="compact"
                autocomplete="false"
                :loading="formNew.validating"
                :disabled="formNew.validating"
                prepend-inner-icon="mdi-account-box-outline"
                :rules="[
                  rulesApellido.requerido,
                  rulesApellido.minChar,
                  rulesApellido.maxChar,
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" class="border-col">
              <v-text-field
                label="Apellido Materno"
                variant="outlined"
                v-model="formNew.apm"
                density="compact"
                autocomplete="false"
                :loading="formNew.validating"
                :disabled="formNew.validating"
                prepend-inner-icon="mdi-account-box-outline"
                :rules="[
                  rulesApellido.requerido,
                  rulesApellido.minChar,
                  rulesApellido.maxChar,
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            color="blue"
            dark
            block
            title
            class="mt-8"
            type="submit"
            :loading="formNew.validating"
            >Registrar</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>

<script>
import { enviarJsonPOST } from "@/helpers/funcionesEstandard";
import {
  rulesRut,
  rulesMail,
  rulesName,
  rulesApellido,
  rulesEmpresa,
  rulesClave,
  rulesNameCredito,
  rulesMonedaMayIgCero,
  rulesSueldo,
  rulesMontoCredConsumo,
  rulesCuotasConsumo,
} from "@/helpers/funcionesEstandard";

import nuevoCredito from "@/components/creditoConsumo/baseNuevoCredito.vue";

export default {
  data: () => ({
    formNew: {
      validForm: null,
      validating: null,
      email: null,
      nombres: null,
      app: null,
      apm: null,
      nombreCredito: null,
      descripcionCredito: null,
      sueldoLiquido: null,
      deudaTotal: null,
      deudaMensual: null,
      rutUsuario: null,
      numeroCuotas: null,
      fechaPrimeraCuota: null,
      sd: null,
      ci: null,
      it: null,
      codigo_tipo_credito: null,
    },
    rulesMail: rulesMail,
    rulesRut: rulesRut,
    rulesName: rulesName,
    rulesApellido: rulesApellido,
    rulesEmpresa: rulesEmpresa,
    rulesClave: rulesClave,
    rulesNameCredito: rulesNameCredito,
    rulesMonedaMayIgCero: rulesMonedaMayIgCero,
    rulesSueldo: rulesSueldo,
    rulesMontoCredConsumo: rulesMontoCredConsumo,
    rulesCuotasConsumo: rulesCuotasConsumo,
    fechaMasSeisMeses: null,
  }),
  mounted: function () {
    this.revisarPaso("mounted");
  },
  watch: {
    step: function (val) {
      this.revisarPaso("watch");
    },
  },
  props: {
    step: Number,
  },
  components: {
    nuevoCredito,
  },
  methods: {
    setValConsumo2: function (clave, valor) {
      this.formNew[clave] = valor;
    },
    setValConsumo: async function (obj) {
      let claves = Object.keys(obj); // claves = ["nombre", "color", "macho", "edad"]
      for (let i = 0; i < claves.length; i++) {
        let clave = claves[i];
        this.formNew[clave] = obj[clave];
      }
    },
    revisarPaso: async function (evento) {
      if (this.step == 2) {
        this.formNew = {
          personaId: null,
          usuarioId: null,
          validForm: null,
          validating: null,
          email: null,
          nombres: null,
          app: null,
          apm: null,
          nombreCredito: null,
          descripcionCredito: null,
          deudaTotal: null,
          deudaMensual: null,
          sueldoLiquido: null,
          rutUsuario: null,
          montoSolicitar: null,
          numeroCuotas: null,
          fechaPrimeraCuota: null,
          sd: null,
          ci: null,
          it: null,
          codigo_tipo_credito: null,
        };
        if (this.$refs.formNew) {
          this.$refs.formNew.reset();
        }
        this.tipoRegistroNuevo = "1";
        if (this.$refs.formNewEmail) {
          this.$refs.formNewEmail.focus();
        }
      }
    },
    submitNew: async function () {
      this.formNew.validating = true;
      const { valid } = await this.$refs.formNew.validate();
      if (valid) {
        let fd = new FormData();
        fd.append("personaId", this.formNew.personaId);
        fd.append("usuarioId", this.formNew.usuarioId);
        fd.append("email", this.formNew.email);
        fd.append("nombres", this.formNew.nombres);
        fd.append("app", this.formNew.app);
        fd.append("apm", this.formNew.apm);
        fd.append("nombreCredito", this.formNew.nombreCredito);
        fd.append("descripcionCredito", this.formNew.descripcionCredito);
        fd.append("deudaTotal", this.formNew.deudaTotal);
        fd.append("deudaMensual", this.formNew.deudaMensual);
        fd.append("sueldoLiquido", this.formNew.sueldoLiquido);
        fd.append("rutUsuario", this.formNew.rutUsuario);
        fd.append("montoSolicitar", this.formNew.montoSolicitar);
        fd.append("numeroCuotas", this.formNew.numeroCuotas);
        fd.append("fechaPrimeraCuota", this.formNew.fechaPrimeraCuota);
        fd.append("sd", this.formNew.sd);
        fd.append("ci", this.formNew.ci);
        fd.append("it", this.formNew.it);
        fd.append("codigo_tipo_credito", this.formNew.codigo_tipo_credito);

        var json = await enviarJsonPOST("usuarios/crear_nueva_cuenta", fd);
        if (json.error == 0) {
          await this.$emit("registraCorreo", this.formNew.email);
          await this.cambiarPaso(3);
        } else {
          await this.$emit("validarMensaje", json);
        }
      }
      this.formNew.validating = false;
    },
    cambiarPaso: async function (paso) {
      this.$emit("cambiarPaso", paso);
    },
  },
};
</script>
<template>
  <v-card-text class="mt-12 border-s-lg pb-3">
    <h3 class="text-center text-sm-h5 pb-3">Cambio de clave temporal</h3>
    <div class="text-center text-caption text-sm-text-body-1 pb-3">
      Debe tener la clave temporal que fue enviada a su correo <br />Con esta
      clave temporal, usted podrá crear su propia clave. <br /><br />Esta nueva
      clave debe debe tener al menos 10 carácteres, a lo menos una mayúscula, una minuscula y un número.
    </div>
    <v-form
      ref="formChange"
      v-model="formChange.validForm"
      @submit.prevent="submitformChange"
    >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" class="mt-5">
          <v-text-field
            label="Password Antigua"
            variant="outlined"
            density="compact"
            autofocus
            ref="formChangePassword"
            autocomplete="false"
            type="password"
            v-model="formChange.passwordOld"
            :loading="formChange.validating"
            :disabled="formChange.validating"
            prepend-inner-icon="mdi-lock"
            :rules="[
              rulesClave.requerido,
              rulesClave.minChar,
              rulesClave.maxChar,
            ]"
          ></v-text-field>
          <v-text-field
            label="Password Nueva"
            variant="outlined"
            density="compact"
            autocomplete="false"
            type="password"
            ref="formChangepasswordNew"
            v-model="formChange.passwordNew"
            :loading="formChange.validating"
            :disabled="formChange.validating"
            prepend-inner-icon="mdi-lock"
            :rules="[
              rulesClave.requerido,
              rulesClave.minChar,
              rulesClave.maxChar,
              rulesClave.valida,
            ]"
          ></v-text-field>
          <v-text-field
            label="Repetir Password nueva"
            variant="outlined"
            density="compact"
            autocomplete="false"
            type="password"
            v-model="formChange.passwordReNew"
            :loading="formChange.validating"
            :disabled="formChange.validating"
            prepend-inner-icon="mdi-lock"
            :rules="[
              rulesClave.requerido,
              rulesClave.minChar,
              rulesClave.maxChar,
              rulesClave.valida,
            ]"
          ></v-text-field>
          <v-btn
            color="blue"
            dark
            block
            title
            type="submit"
            :loading="formChange.validating"
            >Cambiar Clave</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>

<script>
import { enviarJsonPOST } from "@/helpers/funcionesEstandard";
import { rulesClave } from "@/helpers/funcionesEstandard";

export default {
  data: () => ({
    formChange: {
      validForm: null,
      validating: null,
      email: null,
      passwordOld: null,
      passwordNew: null,
      passwordReNew: null,
    },
    rulesClave: rulesClave,
  }),
  mounted: function () {
    this.revisarPaso("mounted");
  },
  watch: {
    step: function (val) {
      this.revisarPaso("watch");
    },
  },
  props: {
    step: Number,
    email: String,
    passwordOld: String,
  },
  methods: {
    revisarPaso: async function (evento) {
      if (this.step == 5) {
        this.formChange = {
          validForm: null,
          validating: null,
          email: null,
          passwordOld: null,
          passwordNew: null,
          passwordReNew: null,
        };
        if (this.$refs.formOPT) {
          this.$refs.formChange.reset();
        }
        if (this.$refs.formOPTopt) {
          this.$refs.formChangePassword.focus();
        }
        if (this.passwordOld) {
          this.formChange.passwordOld = this.passwordOld;
        }
        if (this.email) {
          this.formChange.email = this.email;
        }
      }
    },
    submitformChange: async function () {
      this.formChange.validating = true;
      const { valid } = await this.$refs.formChange.validate();
      if (valid) {
        if (this.formChange.passwordNew == this.formChange.passwordReNew) {
          let fd = new FormData();
          fd.append("email", this.formChange.email);
          fd.append("passwordOld", this.formChange.passwordOld);
          fd.append("passwordNew", this.formChange.passwordNew);
          var json = await enviarJsonPOST("usuarios/cambiar_clave", fd);

          if (json.error == 0) {
            await this.$emit("cambiarPaso", 1);
          }
        } else {
          json = {
            error: 1,
            message: "La clave y su confirmación no son iguales.",
          };
        }
        await this.$emit("validarMensaje", json);
      }
      this.formChange.validating = false;
    },

    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
  },
};
</script>